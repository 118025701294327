import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";


const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#cfcfcf"
};

const scan_inprogress = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color:"#fff",
  background:"#FFA500"
};

class Goodsreceivelist extends Component {

  state = {
    items: [],
    hasMore: true,
    layoutName: "default",
    input: "",
    keyboardVisibility:false,
    handlefindDocument: this.findDocument.bind(this, 'DocumentFind')
  };


  findDocument(refName, e) {
    //alert(e.target.value.length);
    //if (event.charCode === 13) { for enter key
    const docId = e.target.value;
    if(docId.length >= 3){
              fetch('/api/v1/goodsreceipt/docfind/'+docId, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                //console.log( data.id);
                //this.setState({ todoList: data })
                if(data.id > 0 ){
                window.location.replace('/stockdocdetails/'+data.DocNum);
                //console.log( data.id);
                }
                else{
                  this.errors = true;
                  //console.log( data.status);
                  alert('Invalid Document number!');
                }
              }
              );
    
      } //if
    
    };
    componentDidMount() {

      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      localStorage.setItem('StockTransferItemDocEntry','');
      localStorage.setItem('StockTransferItemFromWH','');
      this.refreshList();


      }
     
    refreshList = () => {
      
//alert(localStorage.getItem('UserSelectedDocOption'));
fetch('/api/v1/stockmovment/doclist/3/'+localStorage.getItem('userId')+'/NA', {
  method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}` //3006dc6ed948ee06cb55119eb2323288975548a8f93ecc099cd66786b9740993
      }
    })
      .then(res => res.json())
      .then(data => {
       // console.log( data);
                    if (this.state.items.length >= 500) {
                      this.setState({ hasMore: false });
                      return;
                    }
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  })
            .catch(error => {
              console.log( error);
            });       
        }; 
  
    onChangeEanInput = (e) => {
      //e.preventDefault();
      if(this.state.keyboardVisibility === false){
        this.setState({keyboardVisibility: true});
      }
      else{
        this.setState({keyboardVisibility: false});
      }
    
  };     


  onChange = input => {
    this.setState({
      input: input
    });
    //console.log("Input changed", input);
  };

  onKeyPress = button => {
    //console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    //alert(button);
     if(button === "Close"){
      this.keyPadClose();
     }
   // if (button === "{shift}" || button === "{lock}") this.handleShift();
      if(button === "{enter}"){
        //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
          //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
          //this.findItem.bind(this.state.input, 'DocumentItemFind')
          //this.findItem(this.state.input, 'DocumentItemFind');
          let currKeyEan = this.state.input;
          this.setState({ input: '' });
         // console.log('dddd',currKeyEan);
          this.scanItemUpdate(currKeyEan);
          
          
      }
    
  };
  keyPadClose = () => {
    // alert('s');
     this.setState({keyboardVisibility: false});
     this.setState({keyboardVisibilityQty: false});
     
     const focusField = document.querySelector(
      `input[name=doc_number]`
    );
      focusField.focus();
   
 };

  scanItemUpdate(docId) { 
    this.setState({ input: '' });  

    setTimeout(() => {
               

      fetch('/api/v1/stockmovment/docfind/'+docId, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
              }
            })
              .then(res => res.json())
              .then(data => {            
                if(data.id > 0 ){
                  window.location.replace('/stockdocdetails/'+data.DocNum);
                  //console.log( data.id);
                  }
                  else{
                    this.errors = true;
                    //console.log( data.status);
                    alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
                  }
            }
            )
            .catch(error => {
              alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
          });

  

  }, 500);

  }

  tapToRemoveScannedItemQty = () => {
    const postData = {
      userId: localStorage.getItem('userId'),
      username: localStorage.getItem('username'),
    };
    if (window.confirm("Are you sure to create a new document?")) {
    fetch('/api/v1/goodsreceipt/goodsReceiptItemCreate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then(data => {
      console.log( data);
      //this.findDocument();     
      localStorage.setItem('UserSelectedDocOption', 3);
      window.location.replace('/goodsreceive');

          });     

        }

        }; 
  render() {
    async function removeItem(id,DocNum, userId) {
      const currentUser = localStorage.getItem('userId');
      localStorage.setItem('StockTransferNewDocId',id);
      //alert(localStorage.getItem('StockTransferNewDocId'));
      if(window.confirm('Do you want to delete this Document? Click "Ok" to delete and "Cancel" Otherwise.')){
        fetch('/api/v1/goodsreceipt/goodsReceiptDocDelete/'+id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        })
        .then(res => res.json())
        .then(data => {
          console.log( data);
          window.location.reload();
    
              });  

      }
      else{
            if(userId === null || userId === parseInt(currentUser)){
              /*if(localStorage.getItem('StockTransferItemFromWH') === 'All'){
                window.location.replace('/stockdocdetails/3/'+id+'/'+id);
              }
              else{*/
                window.location.replace('/warehouse/goodsreceive/3'); // let 3 is for ware house wise goods receipt manually
              //}
          }

          else if(window.confirm('The document is currently edited by another user. Do you want to continue?')){
            window.location.replace('/warehouse/goodsreceive/3');

          }
      }
  }




    return (

      <div>
        <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[9]}</h1>
            <div className="mb-4">
            <input
          name='doc_number'
          type='text'
          placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[10]}
          onKeyUp={this.state.handlefindDocument}
          ref={(DocumentFind) => {DocumentFind && DocumentFind.focus() }} 
        />   
              </div>

              
        <hr />
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.refreshList}
          hasMore={this.state.hasMore}
          loader={<h4>.</h4>}
          height={400}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {this.state.items.map((i, index) => (
            
            <Link to='#' onClick={ () => { removeItem(i.id,i.DocNum, i.user_id) }}>
            <div style={i.user_id ?  scan_inprogress : scanned_items} key={index}>
             
              <span className={`todo-title mr-2`} title={i.DocNum}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[12]}:  {i.DocNum}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.DocDate}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.username}
                </span>

              
            </div>
            </Link>
          ))}
        </InfiniteScroll>
        <br />
       
        <div className="mb-12 text-center jumbotron-icon panel_footer">
         <button onClick={() =>{this.tapToRemoveScannedItemQty()}} >
        <i className="fa fa-plus fa-2" aria-hidden="true"></i>
          
        </button>   
          &nbsp;
        
        <button onClick={this.refreshList}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default Goodsreceivelist;