import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";

import Button from 'react-bootstrap/Button';
//import Col from 'react-bootstrap/Col';
//import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
//import Row from 'react-bootstrap/Row';
  
const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#a1eba1"
};

const scanned_pending = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#FFA500"
};
const scanned_binloc = {
  height: 30,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#cfcfcf"
};




class StockDocdetails extends Component {

  state = {
    items: [],
    Fixeditems: [],
    itemDetails:"",
    spinner:false,
    hasQty: false,
    upScanQty: false,
    upScanQtyBin: false,
    totalOrderQty: 0,
    qtyButton: 'Quantity field',
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    handlefindItemInputBin:this.findBinItem.bind(this, 'BinItemFindInput'),
    docItem: [],
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    showHideEnale: false,
    layoutName: "default",
    input: "",
    input3:"",
    input4:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    keyboardVisibilityBinScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    binItem: [],
    //selectdBinLocationId:0,
    setPrevPage:'',
    docEntryLatest:0,
    docIdLatest:0,
  };


  findDocument(docEntry) {
    //StockTransferItemFromWH: localStorage.setItem('StockTransferItemFromWH','');
    //if (event.charCode === 13) { for enter key
    //alert(localStorage.getItem('userId'));
    const docId = this.props.match.params.docId;
    //let docEntry = this.props.match.params.docEntry;
    let OptionId = parseInt(this.props.match.params.optionId);
    let loadCurrentStock = localStorage.getItem('ifCheckCurrentStockItems');
//alert(loadCurrentStock);
//console.log( docEntry)
    if(docId.length > 0){
      this.setState({spinner: true});

      const postData = {
        docEntry: docEntry,
        userId: localStorage.getItem('userId'),
        StockTransferItemFromWH: localStorage.getItem('StockTransferItemFromWH'),
        OptionId: OptionId,
        loadCurrentStock:loadCurrentStock
      };
      //console.log( docEntry)

              fetch('/api/v1/stockmovment/docitems', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(postData)
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data)
                  const Binrows = [];
                  for (let i = 0; i < data.docItemList.length; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data.docItemList[i]);
                  }

                  console.log( Binrows)
                  this.setState({spinner: false});
                this.setState({ docItem: data.document, items: Binrows, Fixeditems:Binrows});
               // this.setState({ items: data.docItemList });
                //console.log( this.state.docItem);
                //console.log( data.docItemList)
                //this.setState({ todoList: data })
                console.log( this.state.docItem);
                let bKUrl = '/stockmovementreqlist/'+this.props.match.params.optionId+'/'+data.document.FromWarehouse;
                if(parseInt(this.props.match.params.optionId) === 2){    
                  if(String(this.state.docItem.FromWarehouse) !=="All")   { 
                    //alert(localStorage.getItem('StockTransferItemFromWH'));
                    //alert(Binrows[0]['FromWarehouseCode']);
                    //String(localStorage.getItem('StockTransferItemFromWH'))===null?localStorage.setItem('StockTransferItemFromWH',Binrows[0]['FromWarehouseCode']):localStorage.getItem('StockTransferItemFromWH');
                    //localStorage.setItem('StockTransferItemFromWH',Binrows[0]['FromWarehouseCode']);
                    if(!localStorage.getItem('StockTransferItemFromWH') && Binrows[0]['FromWarehouseCode']!== null){
                      localStorage.setItem('StockTransferItemFromWH',Binrows[0]['FromWarehouseCode']);
                    }
                    bKUrl = '/warehouse/stockmovementlist/'+this.props.match.params.optionId;
                  }
                  else{
                    bKUrl = '/Stockmovement';
                  }

                }    
               else if(parseInt(this.props.match.params.optionId) === 3){        
                  bKUrl = '/warehouse/goodsreceive/'+this.props.match.params.optionId;
                }   
                else if(parseInt(this.props.match.params.optionId) === 1){   
                  bKUrl = '/stockmovementlist/'+this.props.match.params.optionId+'/'+localStorage.getItem('StockTransferItemFromWH');
                }
                //alert(this.state.docItem.FromWarehouse); stockdocdetails/2/104/104
                this.setState({ setPrevPage: bKUrl });
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
      } //if
    
    };    



  tapToRemoveScannedItemQty = () => {
    //alert(this.state.itemSelectedPrevId);
    if(this.state.itemSelected === true){ 

        if(window.confirm("Do you want to remove scanned items?")){
      fetch('/api/v1/stockmovment/removeScanItem/'+this.state.itemSelectedPrevId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
        //this.findDocument();                             
        window.location.reload(false);  
                  //window.location.replace('/pickandpack');
                });  
       }         
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   
  
  tapToUpdateScannedItemQty = () => {    
    this.setState({ hasQty: false });
    if(this.state.itemSelected === true){ 

          this.setState({upScanQty: true});
          //alert(this.state.input3);
          //alert(this.state.totalOrderQty);
        if(this.state.input3 > 0 ){ 
          fetch('/api/v1/stockmovment/ScanItemQtyUpdate/'+this.state.itemSelectedPrevId+'/'+this.state.input3, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(res => res.json())
          .then(data => {
            //console.log( data);
            this.setState({ input3: "" });
            //this.findDocument();        
            window.location.reload();                     
           
                      //window.location.replace('/pickandpack');
                    });  
           }
           else{
            alert( JSON.parse(sessionStorage.getItem('currentLang'))[43]);
           }      
       
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   

  
tapToFullScreen = () => {

  if(this.state.hasFullScrn === false){
    this.setState({ hasFullScrn: true });
    this.setState({ fullScrenButton: JSON.parse(sessionStorage.getItem('currentLang'))[22] });
  }
    else{
      this.setState({ hasFullScrn: false });
      this.setState({ fullScrenButton: JSON.parse(sessionStorage.getItem('currentLang'))[23] });
    }
  
};

handleSearch = event => {

  //console.log( event.target.value);

  
  //console.log( users);*/
  //console.log( this.state.selectedBinLocQty);
  //console.log( this.state.items);
  let serachTerm = event.target.value.toUpperCase();

  this.highlightItem(serachTerm);

}; 

highlightItem(serachTerm) {
  
  let allItems = this.state.Fixeditems;

  if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; }

  //console.log( allItems);

  /*let filterBySearch = allItems.filter((item) => {
    if (String(item.ItemCode).length >0 && item.ItemCode.toLowerCase().includes(serachTerm.toLowerCase())) 
    { return item; }
   else if (parseInt(item.DocNum) != null && item.DocNum.includes(serachTerm.toLowerCase())) 
   { return item; }  
   else if (String(item.polski_number) != null && item.polski_number.toLowerCase().includes(serachTerm.toLowerCase())) 
   { return item; }    
   else if (String(item.polski_opis) != null && item.polski_opis.toLowerCase().includes(serachTerm.toLowerCase())) 
   { return item; }      
        
})*/


var filterTitle ='';
var filterDocNum ='';
var filterPolNumber ='';
var filterPolskyOpis ='';  
var filterDesc ='';     


var filterBySearch = allItems.filter((item) => {

  if (String(item.ItemCode).length >0){
    return item.ItemCode.toLowerCase().indexOf(serachTerm.toLowerCase()) > -1;
  } 
    //return filterTitle && filterDocNum && filterPolNumber && filterPolskyOpis;
  });
  

  if(filterBySearch.length===0){
    filterBySearch = allItems.filter((item) => {
      if (String(item.DocNum).length >0){
        return item.DocNum.toLowerCase().indexOf(serachTerm.toLowerCase()) > -1;    
        }  
        //return filterTitle && filterDocNum && filterPolNumber && filterPolskyOpis;
      });
      //this.setState({ items: filterDocNum });
    }

    else if(filterBySearch.length===0){
      filterBySearch = allItems.filter((item) => {
        if (String(item.polski_number).length >0){
          return item.polski_number.toLowerCase().indexOf(serachTerm.toLowerCase()) > -1;    
          }  
          //return filterTitle && filterDocNum && filterPolNumber && filterPolskyOpis;
        });
        //this.setState({ items: filterPolNumber });
      }
      else if(filterBySearch.length===0){
        filterBySearch = allItems.filter((item) => {
          if (String(item.polski_opis).length >0){
            return item.polski_opis.toLowerCase().indexOf(serachTerm.toLowerCase()) > -1;    
            }  
            //return filterTitle && filterDocNum && filterPolNumber && filterPolskyOpis;
          });
         // this.setState({ items: filterPolskyOpis });
        }
        


console.log( filterBySearch);
this.setState({ items: filterBySearch });


  
}; 
    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      //alert(localStorage.getItem('UserSelectedDocOption'));
      let eanNum = e.target.value;

      if(localStorage.getItem('UserSelectedDocOption') === '2'){
        //alert(localStorage.getItem('UserSelectedDocOption'));
         eanNum = e.target.value; /////////////////////////////// Work is needed for document item details /////////////////////
      }
      
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 5){


      document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     //console.log(eanNum);

          this.scanItemUpdate(eanNum);

        } //if

      };
      
      scanItemUpdate(eanNum) { 
        

        setTimeout(() => {
          const optionId = this.props.match.params.optionId;         
          //const docId = this.props.match.params.docId;
          //const docEntry = this.props.match.params.docEntry;
          const docId = this.state.docIdLatest;
          const docEntry = this.state.docEntryLatest;
          let scanQty =this.state.qtyInput;
          //alert(docId);
          const jsonbody =  {'eanNum':eanNum,'docNum':parseInt(docId),'docEntry':docEntry,'canQty':scanQty,
          'LineNum':0,'ItemDescription':this.state.itemDetails,'Quantity':0,
          'FromWarehouseCode':localStorage.getItem('StockTransferItemFromWH'),'WarehouseCode': 'NA','U_FromBinCode':'NA','StockTrnasferType':optionId        
        };  //Update once actual data fetch from scanner
        console.log( jsonbody);
          fetch('/api/v1/stockmovment/itemfind', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  },
                  body:JSON.stringify(jsonbody)
                })
                  .then(res => res.json())
                  .then(data2 => {
                 //console.log( data2);
                 let data = data2.ItemDets;
                if( data.ItemCode !=='' ){
                  
                  
                  
                this.setState({itemSelectedPrevId: data.id});
                this.setState({ itemSelected: true });
                //this.callBinlocations();
                  /*if(data2.BinLocs.length >0){
                  this.setState({ binItem: data2.BinLocs});                
                  this.setState({modalShow: true});  
                  }
                  else{
                    alert("No Bin location assigned!");
                  }*/
                  //alert(data.ManageBatchNumbers);
                  localStorage.setItem('pageFrom','stockTransfer');
                  if(String(data.ManageBatchNumbers) === 'Y'){
                    window.location.replace('/stockmovementbinloc/'+optionId+'/'+data.id+'/1/'+data.ItemCode);
                  }
                  else{
                    window.location.replace('/stockmovementbinloc/'+optionId+'/'+data.id+'/0/'+data.ItemCode);
                  }

                }
                else{
                  this.setState({ itemSelected: true });
                }
                 
                 
                }
                )
                .catch(error => {
                  console.log( error);
                  alert("EAN Code not exist!");
              });  

      

      }, 500);

      }
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      console.log( this.state.docItem);
      //this.refreshList();
      localStorage.setItem('BinInput','');
      localStorage.setItem('StockTransferItemDocEntry','');
      localStorage.setItem('StockTransferItemId','');
      localStorage.setItem('StockTransferItemCode','');
      localStorage.setItem('StockTransferItemToWH','');
      localStorage.setItem('AbsEntry','');
      localStorage.setItem('BaseEntry','');
      
      let docEntry = this.props.match.params.docEntry
      localStorage.setItem('StockTransferMultipleDocEntry',docEntry);
      //alert(localStorage.getItem('selectStockTransferType'));
      this.findDocument(docEntry);


      }
     
    refreshList = () => {

    fetch('/api/v1/stockmovment/docitems', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
       // console.log( data);
                    
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  });     
        }; 


        onChange = input => {
          this.setState({
            input: input
          });
          //console.log("Input changed", input);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                this.setState({ input: '' });
               // console.log('dddd',this.state.input);
                this.scanItemUpdate(currKeyEan);
                
                
            }
          
        };

        onChangeQty = input2 => {
          this.setState({ upScanQty: false });
          
          //alert(input2);
              this.setState({
                input2: input2
              });
              //console.log("Input changed", input2);
        };
      
        onKeyPressQty = button2 => {
         // console.log("Button pressed", button2);
         
         this.setState({ upScanQty: false });
          if(button2 === "Close"){
            this.keyPadClose();
           
           }
         // alert(this.state.scanned_ean_code);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
          //if (button2 === "{shift}" || button2 === "{lock}") this.handleShift();
          if(button2 === "{enter}"){
         // this.setState({ input: '' });
           //console.log('dddd',this.state.input2);
          let availQty = this.state.itemSelectedAvailQty;
          let currKeyQtyEan = this.state.scanned_ean_code;
          if(this.state.input2 <= availQty){
            this.keyPadClose();
            
            this.setState({ qtyInput: this.state.input2 });

            
            this.setState({ input2: null });
            this.setState({ scanned_ean_code: null });

            this.scanItemUpdate(currKeyQtyEan);
          }
          else{
            alert("Available quantity for this item is:"+ availQty);
          }
          this.setState({ hasQty: false });
          //const docId = this.props.match.params.id;
          //window.location.replace('http://localhost:3000/docdetails/'+docId);
          //window.location.reload(false);
          }

        };

        onChangeQty2 = input3 => {
          //alert(input2);
          this.setState({ hasQty: false });
              this.setState({
                input3: input3
              });
              //console.log("Input changed", input2);
        };
      
        onKeyPressQty2 = button3 => {
          this.setState({ hasQty: false });
         // console.log("Button pressed", button2);
          if(button3 === "Close"){
            this.setState({ upScanQty: false });
            this.keyPadClose();
           
           }
         // alert(this.state.scanned_ean_code);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
          //if (button2 === "{shift}" || button2 === "{lock}") this.handleShift();
          if(button3 === "{enter}"){
         // this.setState({ input: '' });
           //console.log('dddd',this.state.input2);
          //let availQty = this.state.itemSelectedAvailQty;
//alert(this.state.input3);
this.tapToUpdateScannedItemQty();
          this.setState({ upScanQty: false });
          //const docId = this.props.match.params.id;
          //window.location.replace('http://localhost:3000/docdetails/'+docId);
          //window.location.reload(false);
          }

        };
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          //console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };


        onChangeBin = inputBin => {
          this.setState({ upScanQtyBin: false });
          this.setState({
            input4: inputBin
          });
         
          //console.log("Input changed", inputBin);
        };
        
      
        onKeyPressBin = button4 => {
          //this.setState({ upScanQtyBin: false });
          
          //console.log("Button pressedBin", button4);
          
          //console.log("Bin content", localStorage.getItem('BinInput'));
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button4 === "Keypad-Close"){
            this.BinkeyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            else if(button4 === "{enter}"){
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = localStorage.getItem('BinInput');
                //alert(currKeyEan);
                localStorage.setItem('BinInput','');
               // console.log('dddd',this.state.input);
                this.findBinItem(currKeyEan);
                
                
            }
            else{
              localStorage.setItem('BinInput',localStorage.getItem('BinInput').concat(button4));
            }
          
        };

        onChangeQtyInput2 = e => {
          let QtyInput = e.target.value;
          //alert(input);
          console.log("Button pressed", QtyInput);
          this.setState(
            {
              qtyInput: QtyInput
            }
          );
        };
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

      
      onChangeEanInputBin () {
        //e.preventDefault();
        this.setState({keyboardVisibilityQty: false});
        this.setState({keyboardVisibilityScanQty: false});
        this.setState({keyboardVisibilityBinScanQty: false});


        if(this.state.keyboardVisibilityBinScanQty === false){
          this.setState({keyboardVisibilityBinScanQty: true});
        }
        else{
          this.setState({keyboardVisibilityBinScanQty: false});
        }
      
    };

    findBinItem(toLoc) {
      
      //alert(toLoc);
      if(toLoc.length > 0){
        const jsonbody =  {'docId':this.state.itemSelectedPrevId,'itemCode':this.state.scanned_ean_code,'toBinLoc':toLoc};
        fetch('/api/v1/stockmovment/findBinLoc', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          },
          body:JSON.stringify(jsonbody)
        })
          .then(res => res.json())
          .then(data => {
            //console.log( data)
            this.setState({ binItem: data});                
          //this.setState({modalShow: true}); 
            //localStorage.setItem('selectdBinLocationId','');
            //window.location.reload();
         // this.setState({ items: data.docItemList });
          //console.log( this.state.docItem);
          //console.log( data.docItemList)
          //this.setState({ todoList: data })
          
        }
        )
        .catch(error => {
          //console.log( jsonbody);
          console.log( JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          alert(JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          window.location.reload();
      });
      
        } //if
    }

    onChangeInputBin(e) {
   
      //if (event.charCode === 13) { for enter key
      let toLoc = e.target.value;
     // alert(toLoc);
      if(toLoc.length > 0){
        const jsonbody =  {'docId':this.state.itemSelectedPrevId,'itemCode':this.state.scanned_ean_code,'toBinLoc':toLoc};
        fetch('/api/v1/stockmovment/findBinLoc', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          },
          body:JSON.stringify(jsonbody)
        })
          .then(res => res.json())
          .then(data => {
            //console.log( data)
            this.setState({ binItem: data});                
          //this.setState({modalShow: true}); 
            //localStorage.setItem('selectdBinLocationId','');
            //window.location.reload();
         // this.setState({ items: data.docItemList });
          //console.log( this.state.docItem);
          //console.log( data.docItemList)
          //this.setState({ todoList: data })
          
        }
        )
        .catch(error => {
          //console.log( jsonbody);
          console.log( JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          alert(JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          window.location.reload();
      });
      
        } //if
      
      };    
  
    

        onChangeQtyInput = e => {
         // alert('s');
         this.setState({ upScanQty: false });
         
          this.setState({keyboardVisibility: false});
          this.setState({keyboardVisibilityScanQty: false});
          if(this.state.keyboardVisibilityQty === false){
            this.setState({keyboardVisibilityQty: true});
           }
           else{
            this.setState({keyboardVisibilityQty: false});
           }
        
      };
      onChangeQtyInput3 = e => {
         //alert('s');
         this.setState({ hasQty: false });
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         if(this.state.keyboardVisibilityScanQty === false){
           this.setState({keyboardVisibilityScanQty: true});
          }
          else{
           this.setState({keyboardVisibilityScanQty: false});
          }
       
     };
      keyPadClose = () => {
        // alert('s');
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         this.setState({keyboardVisibilityScanQty: false});
         
         const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
       
     };

     BinkeyPadClose = () => {
      // alert('s');
       
       this.setState({keyboardVisibilityBinScanQty: false});
       
       const focusFieldBin = document.querySelector(
        `input[name=item_ean_bin]`
      );
      focusFieldBin.focus();
     
   };

   scanRepeatConfirm = (itemID) => {
    const currentUser = localStorage.getItem('username');
    const postData = {
      userName: currentUser,
      itemID: itemID,
    };
    if (window.confirm("Are you sure to remove this item?")) {
                  fetch('/api/v1/stockmovment/stockmoveItemRemoveConfirm', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(postData)
                  })
                  .then(res => res.json())
                  .then(data => {
                    console.log( data);
                    //this.findDocument();    
                   window.location.reload();                         
          
                            });     
                }                
        };  

     
     onItemTap = (e,idSeltd,ToBinCode, itemCode,itemDet,DocENtry,DocNum) => {

      if(parseInt(this.props.match.params.optionId) === 3){ // for goods receipt
       //alert(idSeltd);
        this.scanRepeatConfirm(idSeltd);
        return false;
        localStorage.setItem('pageFrom','goodsreceive');
        //localStorage.setItem('StockTransferItemToWH','01');
        localStorage.setItem('StockTransferItemCode',itemCode)
        //window.location.replace('/stockmovementbinloc/3/goodsreceiveFromWH/0/'+itemCode);
        window.location.replace('/warehouse/goodsreceiveFromWH/3');
      }
      //alert(itemCode);
      /*if(ToBinCode){
        return false;
      }*/
      //const itemID = e;
      localStorage.setItem('AbsEntry',DocENtry);
      localStorage.setItem('BaseEntry',DocENtry);
      this.setState({docEntryLatest: DocENtry});
      this.setState({docIdLatest: DocNum});
      //this.findDocument(DocENtry); //refresh list to clear selected itms bg color
      const itemEAN = itemCode;
      this.setState({itemDetails: itemDet});
      this.setState({keyboardVisibility: false});

      //localStorage.setItem('StockTransferItemId',idSeltd);
      //localStorage.setItem('StockTransferItemCode',itemCode);
      
     // alert(itemEAN);
if(this.state.itemSelectedPrevId >0 && ToBinCode !==''){
  document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
  document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
  this.setState({itemSelectedPrevId: idSeltd});
this.setState({itemSelected: false});
}

if(ToBinCode !==''){
document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 70px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: blue none repeat scroll 0% 0%;");
this.setState({itemSelected: true});
}
else{
  if(idSeltd !==''){
    //alert(idSeltd);
    
    document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
    document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 70px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
    this.setState({itemSelected: true});
  }
  else{
   document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
  document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 70px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");
  this.setState({itemSelected: false});
  }
}
this.setState({itemSelectedPrevId: idSeltd});
      this.setState(
        {
          scanned_ean_code: itemEAN
        }
      );
     // alert(this.state.scanned_ean_code);
      /*if(this.state.itemSelected === false){
        this.setState({itemSelected: true});
       }
       else{
        this.setState({itemSelected: false});
       }*/

      const focusField = document.querySelector(
        `input[name=item_ean]`
      );

      // If found, focus the next field
      //if (nextfield !== null) {
        focusField.focus();
      //}
      this.scanItemUpdate(itemEAN);
    };



    callBinlocations() {
      if(this.state.itemSelected === true){ 

        fetch('/api/v1/stockmovment/allbinlocations', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        })
          .then(res => res.json())
          .then(data => {    
            //console.log( data)       
          this.setState({ binItem: data});                
          this.setState({modalShow: true});        
        }
        ).catch(error => {
          console.log( error)
        });  
      }
      else{
        alert( JSON.parse(sessionStorage.getItem('currentLang'))[61]);
      }

      };  

      
      tapToPrevPage = () => {
       //alert();
        //if(parseInt(this.props.match.params.optionId) === 3){ 
          localStorage.setItem('StockTransferItemFromWH','');
          localStorage.setItem('StockTransferItemToWH','');
        //}
        //this.state.setPrevPage
        window.location.replace(this.state.setPrevPage);
        
      };      
    

  render() {    


    function tapBinLoc(binLocation) {
      //const currentUser = localStorage.getItem('userId');
      localStorage.setItem('selectdBinLocationId',binLocation);

  //alert(binLocation);

  }
  function acceptBinLoc(stockDetId,ItemCode) {
    if(localStorage.getItem('selectdBinLocationId') !== null){ 

      
      //new StockDocdetails().findDocument();
      //alert(localStorage.getItem('selectdBinLocationId'));

      
      //this.setState({modalShow: false});  
      //alert(stockDetId);      alert(ItemCode);  
      const jsonbody =  {'docId':stockDetId,'userId':localStorage.getItem('userId'),'itemCode':ItemCode,'toBinLoc':localStorage.getItem('selectdBinLocationId')};
      fetch('/api/v1/stockmovment/updateBinLoc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body:JSON.stringify(jsonbody)
      })
        .then(res => res.json())
        .then(data => {
          //console.log( data)
         
          localStorage.setItem('selectdBinLocationId','');
          window.location.reload();
       // this.setState({ items: data.docItemList });
        //console.log( this.state.docItem);
        //console.log( data.docItemList)
        //this.setState({ todoList: data })
        
      }
      )
      .catch(error => {
        console.log( jsonbody);
        console.log( JSON.parse(sessionStorage.getItem('currentLang'))[33]);
        //alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
    });
    }

    };  


    function MydModalWithGrid(props) {
      //this.callBinlocations();
//alert(props.onHide)
//console.log( props.binData);
      return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Bin Location
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">         
           
          

                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[14]}</h1>
                              <div className="mb-4">
                                    <input
                                  name='item_ean_bin'
                                  id='item_ean_bin'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[17]}
                                  onKeyUp={props.binInputKeyUp}                                
                                  
                                  onChange={props.binInputOnchange}
                                  autoFocus 
                                />   
                                <button onClick={props.binInputKeyPad} >KeyPad</button>
                                
                          </div>
                          {props.isBinKeybordVisible && (
                          <Keyboard
                            keyboardRef={props.binKeyboradRef}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={props.binLayoutName}
                           // onChange={props.binKeyboardChange} //Not working
                            onKeyPress={props.binKeyboardKeyPress} 
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Keypad-Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons:  "Keypad-Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Keypad-Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "q w e r t y u i o p",
                                "a s d f g h j k l {enter}",
                                "z x c v b n m {space}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}


                          
            
              <div>



                
              <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[62]}</h1>
              <InfiniteScroll
                    dataLength={props.binData.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={400}

                  >       
              {props.binData.map((bin, index) => (
                <div key={index}>
                    <Link to='#' onClick={ () => { tapBinLoc(bin.BinCode) }}>
                    <div style={scanned_binloc} >
                                              
                      <div className="items-det">
                      <span className={`todo-title mr-2`} > {bin.BinCode} &nbsp;&nbsp;&nbsp;( Quantity: {bin.Quantity})</span>
                      </div>
                      </div>
                      </Link>                    

                      </div>
                      ))}

</InfiniteScroll>
              </div>
              

           
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={ () => { acceptBinLoc(props.stockDetId,props.stockItemCode) }}>Accept</Button>
            <Button onClick={props.onHide}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);

    return (

      <div>
              {this.state.hasFullScrn === false && 
                <div className="docinfo pick-list-block-div">
                    <div className="mb-4">
                      <h1>{/*{ JSON.parse(sessionStorage.getItem('currentLang'))[34]} : {this.state.docItem.DocNum}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                      <i className="fa fa-arrow-circle-o-down fa-2" aria-hidden="true"></i> &nbsp;
                      { JSON.parse(sessionStorage.getItem('currentLang'))[57]}: {!this.state.docItem.FromWarehouse?localStorage.getItem('StockTransferItemFromWH'):this.state.docItem.FromWarehouse} </h1>
                    </div>
                    
              </div>
              }

          {(this.state.docItem.username ===null && localStorage.getItem('selectStockTransferType')===null)  &&   
          <div className="mb-4 scanblock-comment" align="center">                
              <button onClick={this.readCommentConfirm}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[16]}
              </button>

          </div> 
          
          }

          {(this.state.docItem.username !==null || localStorage.getItem('selectStockTransferType')!==null) &&
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">
                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[14]}</h1>
                              <div className="mb-4">
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[17]}
                                  //onKeyUp={this.state.handlefindItem}
                                // readOnly={true}
                                 // ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={item_ean => this.handleSearch(item_ean)}
                                />   
                                {/*<button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>*/}
                                
                          </div>
                          {/*{this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChange(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons:  "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "q w e r t y u i o p",
                                "a s d f g h j k l {enter}",
                                "z x c v b n m {space}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}*/}
                    </div>
                  {/* }*/}
                  {this.state.upScanQty === true && 
                  <div className="scanblock">
                  <input
                        name='scanned_item_qty_update'
                        type='text'
                        placeholder='Update Quantity'
                        //onKeyUp={this.state.handlefindItemInput}
                        ref="DocumentFind" 
                       //ref={(item_qty_input) => {item_qty_input && item_qty_input.focus() }} 
                        defaultValue=""
                        min='1'
                        max='200'
                        onFocus={() =>{this.onChangeQtyInput3()}} 
                        //onBlur={clse => this.keyPadClose(clse)}
                           />
                          {this.state.keyboardVisibilityScanQty && (
                            <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default hg-layout-numeric numeric-theme"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeQty2(input)}
                            onKeyPress={button => this.onKeyPressQty2(button)}   
                                                   
                            layout={{
                              default: [
                                "Close",
                                "7 8 9 {bksp}",
                                "4 5 6 {enter}",
                                "1 2 3 . 0 clr"
                              ]
                            }}
                            buttonTheme={[
                              {
                                class: "hg-red",
                                buttons: "clr Close"
                              },
                              {
                                class: "hg-highlight",
                                buttons: "clr Close"
                              }
                            ]}
                          />
                          )}
                          </div>
                          }
                
              {this.state.hasQty === true &&     
              <div className="scanblock">
                    <div className="mb-4">                         
                        <input
                        name='item_qty_input'
                        type='text'
                        placeholder='Quantity'
                        //onKeyUp={this.state.handlefindItemInput}
                        ref="DocumentFind" 
                       //ref={(item_qty_input) => {item_qty_input && item_qty_input.focus() }} 
                        defaultValue=""
                        min='1'
                        max='2000'
                        onFocus={() =>{this.onChangeQtyInput()}} 
                        //onBlur={clse => this.keyPadClose(clse)}
                           />
                          {this.state.keyboardVisibilityQty && (
                            <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default hg-layout-numeric numeric-theme"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeQty(input)}
                            onKeyPress={button => this.onKeyPressQty(button)}   
                                                   
                            layout={{
                              default: [
                                "Close",
                                "7 8 9 {bksp}",
                                "4 5 6 {enter}",
                                "1 2 3 . 0 clr"
                              ]
                            }}
                            buttonTheme={[
                              {
                                class: "hg-red",
                                buttons: "clr Close"
                              },
                              {
                                class: "hg-highlight",
                                buttons: "clr Close"
                              }
                            ]}
                          />
                          )}

                    </div>
              </div>
              }



          
        <hr />
              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={275}

                  >
                              {this.state.items.map((i, index) => (

                              <div>                                                  

                                

                                      <Link to='#'  onClick={input => this.onItemTap(input,i.id,i.U_ToBinCode,i.ItemCode,i.ItemDescription,i.DocEntry,i.DocNum)}>
                                                                 
                                        <div className={`itemTap`+i.id} id={`itemTap`+i.id} style={i.U_ToBinCode ?  scanned_items : scanned_pending}  key={i}
                                        >

                                          <div className="items-det">
                                          <span className={`todo-title mr-2`} title={i.ItemCode}>
                                          
                                          {i.DocNum}
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          </span>
                                          <span className={`todo-title mr-2`} title={i.ItemCode}>
                                          {i.ItemCode}
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          
                                          <span className="`todo-title mr-2`">
                                          {parseInt(this.props.match.params.optionId) === 1 && 
                                           <b>{i.Release_Quantity} /</b>
                                          }
                                            <b>{i.Quantity}</b></span>
                                          <br />
                                          {/*<span className="`todo-title mr-2`">                                        
                                            {i.ItemDescription}                                          
                                        </span>*/}
                                            </div>
                                            <div className="items-det">
                                            
                                            
                                                                                     
                                            {parseInt(this.props.match.params.optionId) !== 3 && 
                                            <span>
                                              {JSON.parse(sessionStorage.getItem('currentLang'))[58]}   &nbsp;   
                                            {i.U_FromBinCode?i.U_FromBinCode:i.FromWarehouseCode}
                                            </span>
                                            }
                                            &nbsp;
                                            {JSON.parse(sessionStorage.getItem('currentLang'))[59]}    &nbsp;
                                            {i.U_ToBinCode?i.WarehouseCode+" > " + i.U_ToBinCode:i.WarehouseCode}
                                            </div>
                                        </div>                                       
                                      </Link> 
                                  
                                </div>
                              ))}
                  </InfiniteScroll>
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                   {/*} <button onClick={() =>{this.sendToSap()}} >
                    <i className="fa fa-check fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[28]}                    
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.tapToUpdateScannedItemQty()}} >                    
                    <i className="fa fa-edit fa-2" aria-hidden="true"></i>                    

                    <br />
                    {this.state.qtyButton}
                    </button>  
                    &nbsp;
                    <button onClick={() =>{this.tapToRemoveScannedItemQty()}} >
                    <i className="fa fa-trash fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[68]}  
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.callBinlocations()}} >
                    <i className="fa fa-arrows-h fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[56]}  
                    </button>*/}

                    <button  onClick={() =>{this.tapToPrevPage()}} >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                      
                    </button>
                    
                    &nbsp;
                    {(parseInt(this.props.match.params.optionId) === 2 || parseInt(this.props.match.params.optionId) === 3) && 
                    <Link to={'/Stockmovementitem/'+this.props.match.params.optionId+'/'+this.props.match.params.docEntry+'/'+this.props.match.params.docEntry}>
                    <button >
                    <i className="fa fa-plus fa-2" aria-hidden="true"></i>
                      
                    </button></Link>       }      
                     {/* &nbsp;
                    <button onClick={() =>{this.tapToFullScreen()}} >
                    {this.state.hasFullScrn === false && 
                    <i className="fa fa-arrows-alt fa-2" aria-hidden="true"></i>
                    }

                    {this.state.hasFullScrn === true && 
                    <i className="fa fa-window-minimize fa-2" aria-hidden="true"></i>
                    }
                    
                  </button>*/}
      
                    &nbsp;
                    {this.state.items.length >0 && 
                    <Link to={'/stockmovement/commentscreen/'+this.props.match.params.optionId} onClick={() =>{localStorage.setItem('pageFrom','stockdocdetails');localStorage.setItem('StockTransferDocNum',this.state.docItem.DocNum);localStorage.setItem('StockTransferItemDocEntry',this.state.docItem.DocEntry);localStorage.setItem('AbsEntry',this.state.docItem.DocEntry);localStorage.setItem('BaseEntry',this.state.docItem.DocEntry);}}>
                    <button >
                    <i className="fa fa-arrow-right fa-2" aria-hidden="true"></i>
                      
                    </button>
                    </Link>    }
                </div>
            </div>

          }           
        <MydModalWithGrid show={this.state.modalShow} onHide={() => this.setState({modalShow: false})} binData={this.state.binItem}
        binInputKeyUp={this.state.handlefindItemInputBin}  binInputOnchange={item_ean => this.onChangeInputBin(item_ean)} 

        binInputKeyPad={() =>{this.onChangeEanInputBin()}} isBinKeybordVisible={this.state.keyboardVisibilityBinScanQty}
        binKeyboradRef = {r => (this.keyboard = r)} binLayoutName ={this.state.layoutName} binKeyboardChange ={input => this.onChangeBin(input)}
        binKeyboardKeyPress = {btn => this.onKeyPressBin(btn)} stockDetId = {this.props.match.params.id} stockItemCode = {this.state.itemSelectedPrevId} 
        />
      </div>


    );

  }

}


export default StockDocdetails;