import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { confirm } from "react-confirm-box";

//import SettingsDlogo from '../../ingold/images/settings.png';
import PicklistDlogo from '../../ingold/images/clipboard.png';
import GoodsDlogo from '../../ingold/images/box.png';

const Dashboard = () => {
  const [loading] = useState(true);
  if (localStorage.getItem('token') === null) {
    window.location.replace('/cardlogin');
  } 
  //console.log(sessionStorage.getItem('currentLang'));
  const onClick = async (options) => {
    const result = await confirm("Are you sure?", options);
    if (result) {
     // console.log("You click yes!");
     
    fetch('/api/v1/users/auth/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        localStorage.clear();
        window.location.replace('/cardlogin');
      });
      //return;
    }
    //console.log("You click No!");
  };
  return (
    <div>
      {loading === true && (
        <Fragment>
          <ul className="dash_items">       
            <li><Link to='/pickandpack'><img src={PicklistDlogo} alt="{ JSON.parse(sessionStorage.getItem('currentLang'))[1]}" /> { JSON.parse(sessionStorage.getItem('currentLang'))[1]} </Link></li>
            <li><a href="/stockmovementOption">{ JSON.parse(sessionStorage.getItem('currentLang'))[34]}</a></li>
            <li><a href="/goodsreceive"><img src={GoodsDlogo} alt="{ JSON.parse(sessionStorage.getItem('currentLang'))[2]}" />{ JSON.parse(sessionStorage.getItem('currentLang'))[2]}</a></li>
            <li><a href="/stockcountingOption">{ JSON.parse(sessionStorage.getItem('currentLang'))[80]}</a></li>
            <li><a href="/stockreportOption">{ JSON.parse(sessionStorage.getItem('currentLang'))[81]}</a></li>
            <li><Link to='#'  className="demo-container"  onClick={onClick}> { JSON.parse(sessionStorage.getItem('currentLang'))[4]}</Link></li>
          </ul>
        </Fragment>
        
      )}
    </div>
  );
};

export default Dashboard;
