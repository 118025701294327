exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../fonts/Futura PT Book.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../fonts/Futura PT Book.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../fonts/Futura PT Light.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../fonts/Futura PT Light.woff"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../fonts/Futura PT Medium.woff2"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("../fonts/Futura PT Medium.woff"));

// Module
exports.push([module.id, "@font-face {\n    font-family:'Futura';\n    src: url(" + ___CSS_LOADER_URL___0___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___1___ + ") format('woff');\n    font-weight: 400;\n    font-style: normal;\n}\n@font-face {\n    font-family:'Futura';\n    src: url(" + ___CSS_LOADER_URL___2___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___3___ + ") format('woff');\n    font-weight: 300;\n    font-style: normal;\n}\n@font-face {\n    font-family:'Futura';\n    src: url(" + ___CSS_LOADER_URL___4___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___5___ + ") format('woff');\n    font-weight: 500;\n    font-style: normal;\n}\n", ""]);

