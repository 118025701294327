import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import { isNullOrUndefined } from "html5-qrcode/esm/core";

//import Button from 'react-bootstrap/Button';
//import Col from 'react-bootstrap/Col';
//import Container from 'react-bootstrap/Container';
//import Modal from 'react-bootstrap/Modal';
//import Row from 'react-bootstrap/Row';
  
const scanned_items = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#FFA500"
};
/*const scanned_binloc = {
  height: 30,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#cfcfcf"
};*/




class StockMovementItem extends Component {

  state = {
    items: [],
    Fixeditems: [],
    searchedData:[],
    itemDetails:"",
    hasQty: false,
    upScanQty: false,
    upScanQtyBin: false,
    spinner:false,
    totalOrderQty: 0,
    qtyButton: 'Quantity field',
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    handlefindItemInputBin:this.findBinItem.bind(this, 'BinItemFindInput'),
    docItem: [],
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    showHideEnale: false,
    layoutName: "default",
    input: "",
    input3:"",
    input4:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    keyboardVisibilityBinScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    binItem: [],
    //selectdBinLocationId:0,
    setPrevPage:'',
    incnum: "",
    standAloneItemURL:"",
    existItemQty:0,
  };


  findDocument() {
   
    //if (event.charCode === 13) { for enter key
    //alert(localStorage.getItem('userId'));
    const docId = this.props.match.params.docId;
    const docEntry = this.props.match.params.docEntry;
    const OptionId = parseInt(this.props.match.params.optionId);

    if(docId.length > 0){
      this.setState({spinner: true});
      const postData = {
        docEntry: docEntry,
        userId: localStorage.getItem('userId'),
        StockTransferItemFromWH: localStorage.getItem('StockTransferItemFromWH'),
        OptionId: OptionId,
      };
      console.log( postData);

              fetch('/api/v1/stockmovment/stockDocItemList', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(postData)
              })
                .then(res => res.json())
                .then(data => {
                  this.setState({spinner: false});
                  console.log( data)

                  const Binrows = [];
                  
                  for (let i = 0; i < data.docItemList.length; i++) {
                      Binrows.push(data.docItemList[i]);
                      this.setState({totalBinQty: parseInt(this.state.totalBinQty) + parseInt(data.docItemList[i]['OnHandQty'])})
                  }
                  console.log( "SSSSS");
                this.setState({ docItem: data.document, items: data.docItemList,Fixeditems:Binrows});
                const focusField = document.querySelector(
                  `input[name=item_ean]`
                );
                  focusField.focus();
               // this.setState({ items: data.docItemList });
                //console.log( this.state.docItem);
                //console.log( data.docItemList)
                //this.setState({ todoList: data })
                //console.log( this.state.docItem);
                /*let bKUrl = '/stockmovementreqlist/'+this.props.match.params.optionId+'/'+data.document.FromWarehouse;
                if(parseInt(this.props.match.params.optionId) === 2){     
                  localStorage.setItem('StockTransferNewDocId',docEntry);     

                  if(String(this.state.docItem.FromWarehouse) !=="All")   {                
                  bKUrl = '/warehouse/stockmovementitem/'+this.props.match.params.optionId;
                  }
                  else{
                    bKUrl = '/Stockmovement';
                  }
                }     
                if(parseInt(this.props.match.params.optionId) === 3){     
                  localStorage.setItem('StockTransferNewDocId',docEntry);      
                  localStorage.setItem('StockTransferItemToWH','');              
                  bKUrl = '/stockdocdetails/'+this.props.match.params.optionId+'/'+docEntry+'/'+docEntry;
                }  
                
                //alert(this.state.docItem.FromWarehouse);
                if(data.document.FromWarehouse){
                this.setState({ setPrevPage: bKUrl });
                }*/
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
      } //if
    
    };    

    handleSearch = event => {

      
      //console.log( users);*/
      //console.log( this.state.selectedBinLocQty);
      //console.log( this.state.items);
      let serachTerm = event.target.value.toUpperCase();

      this.highlightItem(serachTerm);

    }; 

    highlightItem(serachTerm) {

      
      let allItems = this.state.Fixeditems;
      
      if (serachTerm === "") { this.setState({ items: this.state.Fixeditems });this.setState({ itemSelected: false }); return ; }


      let filterBySearch = allItems.filter((item) => {
        if (item.BarCodes.Barcode !== null && item.BarCodes.Barcode.toLowerCase().includes(serachTerm.toLowerCase())) {this.setState({ itemSelected: false }); return item; }
        if (item.Items.ItemCode !== null && item.Items.ItemCode.toLowerCase().includes(serachTerm.toLowerCase())) {this.setState({ itemSelected: false }); return item; }
        //if (item.Items.ItemName !== null && item.Items.ItemName.toLowerCase().includes(serachTerm.toLowerCase())) {this.setState({ itemSelected: false }); return item; }
            
    })

      const selectedBinLoc = allItems.filter(
        binLoc => binLoc.Items.ItemCode.indexOf(serachTerm) !== -1
        
      );
     // console.log( serachTerm);
      //console.log( filterBySearch);
      //setSearchedData(data);
      //const data3 = users.filter(
        //user => user.name.indexOf(event.target.value) !== -1
      //); Fixeditems
      //this.setState({ searchedData: selectedBinLoc });    
        if(this.state.itemSelected === false && document.getElementById('itemTap0') != null){
          document.getElementById('itemTap0').removeAttribute("style");  
          document.getElementById('itemTap0').setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
        
        }

      
      this.setState({ items: filterBySearch });    
      //this.callToRefreshInputbox();
      
    }; 

    callToRefreshInputbox(){
      setTimeout(() => {
        document.getElementById('item_ean').value=''; 
        const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
          this.setState({ items: this.state.Fixeditems }); 

    }, 2000);

    }
    

  tapToRemoveScannedItemQty = () => {
    //alert(this.state.itemSelectedPrevId);
    if(this.state.itemSelected === true){ 

        if(window.confirm("Do you want to remove scanned items?")){
      fetch('/api/v1/stockmovment/removeScanItem/'+this.state.itemSelectedPrevId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
        //this.findDocument();                             
        window.location.reload(false);  
                  //window.location.replace('/pickandpack');
                });  
       }         
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   
  
  tapToUpdateScannedItemQty = () => {    
    this.setState({ hasQty: false });
    if(this.state.itemSelected === true){ 

          this.setState({upScanQty: true});
          //alert(this.state.input3);
          //alert(this.state.totalOrderQty);
        if(this.state.input3 > 0 ){ 
          fetch('/api/v1/stockmovment/ScanItemQtyUpdate/'+this.state.itemSelectedPrevId+'/'+this.state.input3, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(res => res.json())
          .then(data => {
            //console.log( data);
            this.setState({ input3: "" });
            //this.findDocument();        
            window.location.reload();                     
           
                      //window.location.replace('/pickandpack');
                    });  
           }
           else{
            alert( JSON.parse(sessionStorage.getItem('currentLang'))[43]);
           }      
       
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   

  
tapToFullScreen = () => {

  if(this.state.hasFullScrn === false){
    this.setState({ hasFullScrn: true });
    this.setState({ fullScrenButton: JSON.parse(sessionStorage.getItem('currentLang'))[22] });
  }
    else{
      this.setState({ hasFullScrn: false });
      this.setState({ fullScrenButton: JSON.parse(sessionStorage.getItem('currentLang'))[23] });
    }
  
};


    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      //alert(localStorage.getItem('UserSelectedDocOption'));
      let eanNum = e.target.value;

      /*if(localStorage.getItem('UserSelectedDocOption') === '2'){
        //alert(localStorage.getItem('UserSelectedDocOption'));
         eanNum = e.target.value; /////////////////////////////// Work is needed for document item details /////////////////////
      }*/
      
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 10){

          setTimeout(() => {
            document.getElementById('item_ean').value=''; 
            this.setState({ itemSelected: false }); 
            const focusField = document.querySelector(
              `input[name=item_ean]`
            );
              focusField.focus();
              this.setState({ items: this.state.Fixeditems }); 
              var number_of_elements = document.getElementsByClassName('itemTap').length;

              var i =0;
              if( document.getElementById('itemTap0') != null){
              document.getElementById('itemTap0').removeAttribute("style");
              document.getElementById('itemTap0').setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
              }
              while (i<number_of_elements) {
                document.getElementsByClassName('itemTap')[i].style.backgroundColor= 'rgb(255, 165, 0)';
                //selecting each class and changing it's backgroundcolor
                i++;
              } 
              
              //document.getElementById("itemTap").style.backgroundColor = "rgb(255, 165, 0)"; 
             // document.getElementById('itemTap').removeAttribute("style");  
              //document.getElementById('itemTap').setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
         

              
    
        }, 600000);
      //document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     //console.log(eanNum);

          //this.scanItemUpdate(eanNum);

        } //if

      };
      
      scanItemUpdate(eanNum) { 
        

        setTimeout(() => {
          const optionId = this.props.match.params.optionId;         
          const docId = this.props.match.params.docId;
          const docEntry = this.props.match.params.docEntry;
          let scanQty =this.state.qtyInput;
          //alert(docId);
          if(this.state.scanned_ean_code === null){
            this.setState({ scanned_ean_code: eanNum });
          const jsonbody =  {'eanNum':eanNum,'docNum':parseInt(docId),'docEntry':docEntry,'canQty':scanQty,
          'LineNum':0,'ItemDescription':this.state.itemDetails,'Quantity':0,
          'FromWarehouseCode':localStorage.getItem('StockTransferItemFromWH'),'WarehouseCode': 'NA','U_FromBinCode':'NA','StockTrnasferType':optionId        
        };  //Update once actual data fetch from scanner
          fetch('/api/v1/stockmovment/itemfind', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  },
                  body:JSON.stringify(jsonbody)
                })
                  .then(res => res.json())
                  .then(data2 => {
                 console.log( data2);
                 let data = data2.ItemDets;
                if( data.ItemCode !=='' ){
                  
                  
                  
                this.setState({itemSelectedPrevId: data.id});
                this.setState({ itemSelected: true });
                //this.callBinlocations();
                  /*if(data2.BinLocs.length >0){
                  this.setState({ binItem: data2.BinLocs});                
                  this.setState({modalShow: true});  
                  }
                  else{
                    alert("No Bin location assigned!");
                  }*/
                  if(data.polski_opis === null){
          
          
                    window.location.replace('/stockmovementbinloc/'+optionId+'/'+data.id+'/0/'+data.ItemCode);
                  }
                  else{
                    window.location.replace('/stockmovementbinloc/'+optionId+'/'+data.id+'/1/'+data.ItemCode);
                  }

                }
                else{
                  this.setState({ itemSelected: true });
                }
                 
                 
                }
                )
                .catch(error => {
                  console.log( error);
                  alert("EAN Code not exist!");
              });  

            }

      }, 500);

      }
     
    componentDidMount() {
      
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      console.log( this.state.docItem);
      //this.refreshList();
      localStorage.setItem('BinInput','');
      localStorage.setItem('StockTransferItemQtyForNewDoc','');
      localStorage.setItem('fromPage',"");
      localStorage.setItem('StockTransferDefaultBin','');

      let bKUrl = '';
      /*const OptionId = parseInt(this.props.match.params.optionId);
      if(OptionId === 3){
        bKUrl ='warehouse/goodsreceive/3';
      }
      else{
        bKUrl ='/stockdocdetails/'+this.props.match.params.optionId+'/'+this.props.match.params.docEntry+'/'+this.props.match.params.docEntry;
      }*/
      if(parseInt(this.props.match.params.optionId) === 2){     
        localStorage.setItem('StockTransferNewDocId',this.props.match.params.docEntry);     
//alert(localStorage.getItem('StockTransferItemFromWH'));
//alert(this.state.docItem.FromWarehouse);
        if(String(this.state.docItem.FromWarehouse) !=="All")   {   
          if(parseInt(localStorage.getItem('selectStockTransferType')) === 1 || parseInt(localStorage.getItem('selectStockTransferType')) === 2){
          bKUrl = '/stockdocdetails/'+this.props.match.params.optionId+'/'+this.props.match.params.docEntry+'/'+this.props.match.params.docEntry;
          }
          else{
          bKUrl = '/warehouse/stockmovementitem/'+this.props.match.params.optionId;
          }
        }
        else{
          bKUrl = '/Stockmovement';
        }
      }     
      if(parseInt(this.props.match.params.optionId) === 3){     
        localStorage.setItem('StockTransferNewDocId',this.props.match.params.docEntry);      
        localStorage.setItem('StockTransferItemToWH','');              
        bKUrl ='/stockdocdetails/'+this.props.match.params.optionId+'/'+this.props.match.params.docEntry+'/'+this.props.match.params.docEntry;
      }  
     
      this.setState({ setPrevPage: bKUrl });
      
      //alert(this.state.setPrevPage);
      this.findDocument();

      }
     
   


        onChange = input => {
          this.setState({
            input: input
          });
          //console.log("Input changed", input);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                this.setState({ input: '' });
               // console.log('dddd',this.state.input);
                this.scanItemUpdate(currKeyEan);
                
                
            }
          
        };

        onChangeQty = input2 => {
          this.setState({ upScanQty: false });
          
          //alert(input2);
              this.setState({
                input2: input2
              });
              //console.log("Input changed", input2);
        };
      
        onKeyPressQty = button2 => {
         // console.log("Button pressed", button2);
         
         this.setState({ upScanQty: false });
          if(button2 === "Close"){
            this.keyPadClose();
           
           }
         // alert(this.state.scanned_ean_code);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
          //if (button2 === "{shift}" || button2 === "{lock}") this.handleShift();
          if(button2 === "{enter}"){
         // this.setState({ input: '' });
           //console.log('dddd',this.state.input2);
          let availQty = this.state.itemSelectedAvailQty;
          let currKeyQtyEan = this.state.scanned_ean_code;
          if(this.state.input2 <= availQty){
            this.keyPadClose();
            
            this.setState({ qtyInput: this.state.input2 });

            
            this.setState({ input2: null });
            this.setState({ scanned_ean_code: null });

            this.scanItemUpdate(currKeyQtyEan);
          }
          else{
            alert("Available quantity for this item is:"+ availQty);
          }
          this.setState({ hasQty: false });
          //const docId = this.props.match.params.id;
          //window.location.replace('http://localhost:3000/docdetails/'+docId);
          //window.location.reload(false);
          }

        };

        onChangeQty2 = input3 => {
          //alert(input2);
          this.setState({ hasQty: false });
              this.setState({
                input3: input3
              });
              //console.log("Input changed", input2);
        };
      
        onKeyPressQty2 = button3 => {
          this.setState({ hasQty: false });
         // console.log("Button pressed", button2);
          if(button3 === "Close"){
            this.setState({ upScanQty: false });
            this.keyPadClose();
           
           }
         // alert(this.state.scanned_ean_code);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
          //if (button2 === "{shift}" || button2 === "{lock}") this.handleShift();
          if(button3 === "{enter}"){
         // this.setState({ input: '' });
           //console.log('dddd',this.state.input2);
          //let availQty = this.state.itemSelectedAvailQty;
//alert(this.state.input3);
this.tapToUpdateScannedItemQty();
          this.setState({ upScanQty: false });
          //const docId = this.props.match.params.id;
          //window.location.replace('http://localhost:3000/docdetails/'+docId);
          //window.location.reload(false);
          }

        };
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          //console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };


        onChangeBin = inputBin => {
          this.setState({ upScanQtyBin: false });
          this.setState({
            input4: inputBin
          });
         
          //console.log("Input changed", inputBin);
        };
        
      
        onKeyPressBin = button4 => {
          //this.setState({ upScanQtyBin: false });
          
          //console.log("Button pressedBin", button4);
          
          //console.log("Bin content", localStorage.getItem('BinInput'));
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button4 === "Keypad-Close"){
            this.BinkeyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            else if(button4 === "{enter}"){
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = localStorage.getItem('BinInput');
                //alert(currKeyEan);
                localStorage.setItem('BinInput','');
               // console.log('dddd',this.state.input);
                this.findBinItem(currKeyEan);
                
                
            }
            else{
              localStorage.setItem('BinInput',localStorage.getItem('BinInput').concat(button4));
            }
          
        };

        onChangeQtyInput2 = e => {
          let QtyInput = e.target.value;
          //alert(input);
          console.log("Button pressed", QtyInput);
          this.setState(
            {
              qtyInput: QtyInput
            }
          );
        };
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

      
      onChangeEanInputBin () {
        //e.preventDefault();
        this.setState({keyboardVisibilityQty: false});
        this.setState({keyboardVisibilityScanQty: false});
        this.setState({keyboardVisibilityBinScanQty: false});


        if(this.state.keyboardVisibilityBinScanQty === false){
          this.setState({keyboardVisibilityBinScanQty: true});
        }
        else{
          this.setState({keyboardVisibilityBinScanQty: false});
        }
      
    };

    findBinItem(toLoc) {
      
      //alert(toLoc);
      if(toLoc.length > 0){
        const jsonbody =  {'docId':this.state.itemSelectedPrevId,'itemCode':this.state.scanned_ean_code,'toBinLoc':toLoc};
        fetch('/api/v1/stockmovment/findBinLoc', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          },
          body:JSON.stringify(jsonbody)
        })
          .then(res => res.json())
          .then(data => {
            //console.log( data)
            this.setState({ binItem: data});                
          //this.setState({modalShow: true}); 
            //localStorage.setItem('selectdBinLocationId','');
            //window.location.reload();
         // this.setState({ items: data.docItemList });
          //console.log( this.state.docItem);
          //console.log( data.docItemList)
          //this.setState({ todoList: data })
          
        }
        )
        .catch(error => {
          //console.log( jsonbody);
          console.log( JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          alert(JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          window.location.reload();
      });
      
        } //if
    }

    onChangeInputBin(e) {
   
      //if (event.charCode === 13) { for enter key
      let toLoc = e.target.value;
     // alert(toLoc);
      if(toLoc.length > 0){
        const jsonbody =  {'docId':this.state.itemSelectedPrevId,'itemCode':this.state.scanned_ean_code,'toBinLoc':toLoc};
        fetch('/api/v1/stockmovment/findBinLoc', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          },
          body:JSON.stringify(jsonbody)
        })
          .then(res => res.json())
          .then(data => {
            //console.log( data)
            this.setState({ binItem: data});                
          //this.setState({modalShow: true}); 
            //localStorage.setItem('selectdBinLocationId','');
            //window.location.reload();
         // this.setState({ items: data.docItemList });
          //console.log( this.state.docItem);
          //console.log( data.docItemList)
          //this.setState({ todoList: data })
          
        }
        )
        .catch(error => {
          //console.log( jsonbody);
          console.log( JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          alert(JSON.parse(sessionStorage.getItem('currentLang'))[63]);
          window.location.reload();
      });
      
        } //if
      
      };    
  
    

        onChangeQtyInput = e => {
         // alert('s');
         this.setState({ upScanQty: false });
         
          this.setState({keyboardVisibility: false});
          this.setState({keyboardVisibilityScanQty: false});
          if(this.state.keyboardVisibilityQty === false){
            this.setState({keyboardVisibilityQty: true});
           }
           else{
            this.setState({keyboardVisibilityQty: false});
           }
        
      };
      onChangeQtyInput3 = e => {
         //alert('s');
         this.setState({ hasQty: false });
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         if(this.state.keyboardVisibilityScanQty === false){
           this.setState({keyboardVisibilityScanQty: true});
          }
          else{
           this.setState({keyboardVisibilityScanQty: false});
          }
       
     };
      keyPadClose = () => {
        // alert('s');
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         this.setState({keyboardVisibilityScanQty: false});
         
         const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
       
     };

     BinkeyPadClose = () => {
      // alert('s');
       
       this.setState({keyboardVisibilityBinScanQty: false});
       
       const focusFieldBin = document.querySelector(
        `input[name=item_ean_bin]`
      );
      focusFieldBin.focus();
     
   };

     
     onItemTap = (e,idSeltd,ToBinCode, itemCode,itemDet,isBatch,Qty,defaultBin,indx) => {
      const OptionId = parseInt(this.props.match.params.optionId);

      this.checkForItemTotalQTY(this.props.match.params.docEntry,itemCode,Qty);
      /*alert(this.state.existItemQty);
      let remainQTY = parseInt(Qty) - parseInt(this.state.existItemQty);
      alert(Qty);
      alert(remainQTY);

      this.setState({totalOrderQty: Qty});
      this.setState({incnum: Qty}); */
      localStorage.setItem('StockTransferItemCode',itemCode.replaceAll("/","-"));
      localStorage.setItem('StockTransferItemDet',itemDet);
      //alert(defaultBin);
      
      
      //localStorage.setItem('StockTransferItemPolskiOpis',U_PolskiOpis);
      //localStorage.setItem('StockTransferItemPLNumer',U_PLNumer);
      //localStorage.setItem('StockTransferItemManageBatchNumbers',ManageBatchNumbers);

      localStorage.setItem('StockTransferItemDocEntry',this.props.match.params.docEntry);
      //alert(isBatch);
      //window.location.replace('/stockmovementbinloc/2/0/0/'+itemCode);
      //alert(itemCode);
      //return false;
      if(ToBinCode){
        return false;
      }
      //const itemID = e;
      //alert(idSeltd);
      
     /* if(parseInt(OptionId) === 2){
        //alert(Qty)
        localStorage.setItem('StockTransferItemQtyForNewDoc',Qty);
        localStorage.setItem('fromPage',"stockmovementitem");
        //alert('d');itemSelected
        this.setState({itemSelected: true});
      //window.location.replace('/stockmovementbinloc/'+OptionId+'/'+idSeltd+'/'+isBatch+'/'+itemCode);
      //return false;
      }*/
      
      //this.findDocument(); //refresh list to clear selected itms bg color
      const itemEAN = itemCode.replaceAll("/","-");
      this.setState({itemDetails: itemDet});
      this.setState({keyboardVisibility: false});
      
     // alert(itemEAN);
     if(this.state.itemSelectedPrevId === 0 && ToBinCode !==''){
     document.getElementById('itemTap0').removeAttribute("style");  
     document.getElementById('itemTap0').setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
     }
if(this.state.itemSelectedPrevId >0 && ToBinCode !==''){
  document.getElementById('itemTap0').removeAttribute("style");  
  document.getElementById('itemTap0').setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");

  if(document.getElementById('itemTap'+this.state.itemSelectedPrevId) != null){   
  document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
  document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
  
  this.setState({itemSelected: false});
  }
  else{
    document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
    document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: blue none repeat scroll 0% 0%;");
    this.setState({itemSelected: true});  }
  this.setState({itemSelectedPrevId: idSeltd});
}

if(ToBinCode !== undefined){
document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: blue none repeat scroll 0% 0%;");
this.setState({itemSelected: true});
}
else{
  if(idSeltd !==''){
    document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
    document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
    this.setState({itemSelected: true});
  }
  else{
   document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
  document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");
  this.setState({itemSelected: false});
  }
}
this.setState({itemSelectedPrevId: idSeltd});
      this.setState(
        {
          scanned_ean_code: itemEAN
        }
      );

      if(parseInt(OptionId) === 2){
        //alert(localStorage.getItem('selectStockTransferType'));
        //localStorage.setItem('StockTransferItemQtyForNewDoc',Qty);
        localStorage.setItem('pageFrom',"stockmovementitem");
        //alert('d');itemSelected
        this.setState({itemSelected: true});
      //window.location.replace('/stockmovementbinloc/'+OptionId+'/'+idSeltd+'/'+isBatch+'/'+itemCode);
      if(parseInt(localStorage.getItem('selectStockTransferType')) === 2){
        this.setState({standAloneItemURL: '/warehouse/stockmovementitem/'+OptionId});
      }
      else{
      this.setState({standAloneItemURL: '/stockmovementbinloc/'+OptionId+'/'+idSeltd+'/'+isBatch+'/'+itemCode});
      }
      return false;
      }
      if(parseInt(OptionId) === 3){
        //document.getElementById('item_ean').value=''; 
        localStorage.setItem('pageFrom',"goodsreceive");
        //alert(Qty);
        /*if(defaultBin.length >0){
        localStorage.setItem('StockTransferDefaultBin',defaultBin);
        
        }
        localStorage.setItem('StockTransferDefaultBinQty',Qty);*/
        //alert('d');itemSelected  {(parseInt(this.props.match.params.optionId) === 3 && localStorage.getItem('StockTransferItemFromWH') ==='02')
        this.setState({itemSelected: true});
      //window.location.replace('/stockmovementbinloc/'+OptionId+'/'+idSeltd+'/'+isBatch+'/'+itemCode);
      //this.setState({standAloneItemURL: '/stockmovementbinloc/'+OptionId+'/'+idSeltd+'/'+isBatch+'/'+itemCode});
      this.setState({standAloneItemURL: '/warehouse/goodsreceiveFromWH/'+OptionId});
      return false;
      }
      this.findDocument();
     // alert(this.state.scanned_ean_code);
      /*if(this.state.itemSelected === false){
        this.setState({itemSelected: true});
       }
       else{
        this.setState({itemSelected: false});
       }*/

      const focusField = document.querySelector(
        `input[name=item_ean]`
      );

      // If found, focus the next field
      //if (nextfield !== null) {
        focusField.focus();
      //}
      this.scanItemUpdate(itemEAN);
    };

    


    checkForItemTotalQTY(DocId,itemCode,BinQTY) {
      const jsonbody =  {'DocId':DocId,'itemCode':itemCode,'BinQTY':BinQTY };  

        fetch('/api/v1/stockmovment/checkItemTotalQTY', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          },body:JSON.stringify(jsonbody)
        })
          .then(res => res.json())
          .then(data => {    
            console.log( data.QTY) ;
           //this.setState({existItemQty: data.QTY}); 
           // return data.
           let remainQTY = parseInt(BinQTY);
           if(parseInt(data.QTY)>0){
            remainQTY = parseInt(BinQTY) - parseInt(data.QTY);
           }           
      //alert(remainQTY);

      this.setState({totalOrderQty: remainQTY});
      this.setState({incnum: remainQTY});
              
        }
        ).catch(error => {
          console.log( error)
        });  
      

      };  

      
      
      incNum =()=>{
       // this.setState({incnum: Number(this.state.incnum)+1}); 

        if(parseInt(this.state.totalOrderQty) >= Number(this.state.incnum)+1)
      {
      this.setState({incnum: Number(this.state.incnum)+1}); 
      
      }
      else{
        this.setState({incnum: this.state.incnum}); 
      }
      
      };
       decNum = () => {
         if(this.state.incnum>0)
         {
          this.setState({incnum: Number(this.state.incnum)-1}); 
         }
      }
      
      handleChange = (e)=>{
       //this.setState({incnum: e.target.value});

       if(parseInt(this.state.totalOrderQty) >= parseInt(e.target.value)){
        this.setState({incnum: parseInt(e.target.value)});
         }
         else{
           this.setState({incnum: 0});
         }
       
      }
       
      updateSeltdItemBinQty = ()=> {
        //alert(this.state.incnum);
        //alert(this.state.standAloneItemURL);
        if(String(localStorage.getItem('pageFrom')) === 'goodsreceive' && parseInt(this.props.match.params.optionId) === 3 ){
          //localStorage.setItem('StockTransferItemFromBin','NA');
          localStorage.setItem('StockTransferItemToWH',''); 
          //localStorage.setItem('StockTransferItemToWH',localStorage.getItem('StockTransferItemFromWH'));          
        }
        if(parseInt(this.state.incnum)>0){
          localStorage.setItem('StockTransferItemQtyForNewDoc',this.state.incnum);
        window.location.replace(this.state.standAloneItemURL);
        }
        else{
          alert("Please enter the Qty!");
        }
        
      }


  render() {    
    
    
    return (

      <div>
              {this.state.hasFullScrn === false && 
                <div className="docinfo pick-list-block-div">
                    <div className="mb-4">
                      <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[34]} : {this.state.docItem.DocNum}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <i className="fa fa-arrow-circle-o-down fa-2" aria-hidden="true"></i> &nbsp;
                      { JSON.parse(sessionStorage.getItem('currentLang'))[57]}: {!this.state.docItem.FromWarehouse?localStorage.getItem('StockTransferItemFromWH'):this.state.docItem.FromWarehouse} </h1>
                    </div>
                    
              </div>
              }

          {/*{this.state.docItem.username ===null &&   
          <div className="mb-4 scanblock-comment" align="center">                
              <button onClick={this.readCommentConfirm}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[16]}
              </button>

          </div> 
          
          }

        {this.state.docItem.username !==null &&*/}
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">
                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[14]}</h1>
                              <div className="mb-4">
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[17]}
                                  onKeyUp={this.state.handlefindItem}
                                  onChange={itemcode => this.handleSearch(itemcode)}
                                // readOnly={true}
                                  //ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                />   
                                {/*<button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>*/}
                                
                          </div>
                          
                    </div>
                  {/* }*/}
                  
                  {this.state.spinner && <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>}
          
        <hr />
        
              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={275}

                  >
                              { this.state.items.map((i, index) => (
                                <div>
                                {i.Items.ItemCode.indexOf('_conf') === -1 &&

                              <div>     

                                {(parseInt(this.props.match.params.optionId) === 3 && localStorage.getItem('StockTransferItemFromWH') ==='02') &&
                                
                                <Link to='#'  onClick={input => this.onItemTap(input,index,i.U_ToBinCode,i.Items.ItemCode,i.Items.ItemName,i.Items.ManageBatchNumbers==="Y"? 1:0,i['Items/ItemWarehouseInfoCollection']['InStock'],'',1)}>
                                                           
                                  <div className={`itemTap`+index} id={`itemTap`+index} style={i.U_ToBinCode ?  scanned_items : scanned_pending}  key={i}
                                  >

                                    <div className="items-det">
                                    <span className={`todo-title mr-2`} title={i.Items.ItemCode}>
                                    {i.Items.ItemCode} 
                                    </span>       
                                    {(parseInt(this.props.match.params.optionId) === 2 || parseInt(this.props.match.params.optionId) === 3 ) &&                                    
                                    <span className={`todo-title mr-2`} title={i['Items/ItemWarehouseInfoCollection']['InStock']}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {i['Items/ItemWarehouseInfoCollection']['InStock']} 
                                    </span>
                                    }                             
                                    
                                    {/*<span className="`todo-title mr-2`">                                        
                                      {i.Items.ItemName}                                          
                                  </span>*/}
                                      {i.BarCodes.Barcode &&
                                     
                                    <span className="`todo-title mr-2`">     
                                     <br />                                   
                                      {i.BarCodes.Barcode}                                          
                                      </span>}
                                      </div>
                                     
                                  </div>                                       
                                </Link> 
                                }                                             
                                {localStorage.getItem('StockTransferItemFromWH') !=='02' && 
                              
                                <Link to='#'  onClick={input => this.onItemTap(input,index,i.U_ToBinCode,i.Items.ItemCode,i.Items.ItemName,i.Items.ManageBatchNumbers==="Y"? 1:0,parseInt(i['Items/ItemWarehouseInfoCollection']['InStock']) !== undefined? i['Items/ItemWarehouseInfoCollection']['InStock']:0,String(i.BinLocations.BinCode).length >0? i.BinLocations.BinCode : '',1)}>
                                                           
                                  <div className={`itemTap`+index} id={`itemTap`+index} style={i.U_ToBinCode ?  scanned_items : scanned_pending}  key={i}
                                  >

                                    <div className="items-det">
                                    <span className={`todo-title mr-2`} title={i.Items.ItemCode}>
                                    {i.Items.ItemCode} 
                                    </span>       
                                    {(parseInt(this.props.match.params.optionId) === 2 || parseInt(this.props.match.params.optionId) === 3 ) &&                                    
                                    <span className={`todo-title mr-2`} title={i['Items/ItemWarehouseInfoCollection']['InStock']}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {i['Items/ItemWarehouseInfoCollection']['InStock']} 
                                    </span>
                                    }                             
                                    {/*<span className="`todo-title mr-2`">                                        
                                      {i.Items.ItemName}                                          
                                  </span>*/}
                                      {i.BarCodes.Barcode &&
                                     
                                    <span className="`todo-title mr-2`">     
                                     <br />                                   
                                      {i.BarCodes.Barcode}                                          
                                      </span>}
                                      </div>
                                     
                                  </div>                                       
                                </Link> 
                                    }
                                </div>
  }
  </div>
                              ))}


                  </InfiniteScroll>
                  {this.state.itemSelected && !localStorage.getItem('StockTransferItemToWH') && 
                  <div className={`col-xl-3`}>
              <div className={`input-group`}>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.decNum()}}>-</button>
              </div>
              <input type="text" id="increamentValue" name="increamentValue" className={`form-control`} value={this.state.incnum} onChange={(i) =>{this.handleChange(i)}}/>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.incNum()}}>+</button>
              </div>
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={() =>{this.updateSeltdItemBinQty()}}>QTY Save</button>
              </div>
            </div>
              
              </div>}
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                   {/*} <button onClick={() =>{this.sendToSap()}} >
                    <i className="fa fa-check fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[28]}                    
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.tapToUpdateScannedItemQty()}} >                    
                    <i className="fa fa-edit fa-2" aria-hidden="true"></i>                    

                    <br />
                    {this.state.qtyButton}
                    </button>  
                    &nbsp;
                    <button onClick={() =>{this.tapToRemoveScannedItemQty()}} >
                    <i className="fa fa-trash fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[68]}  
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.callBinlocations()}} >
                    <i className="fa fa-arrows-h fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[56]}  
                    </button>*/}

                   <Link to={this.state.setPrevPage}>
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                      
                    </button>
                    </Link>                  
                    &nbsp;
                    <button onClick={() =>{this.findDocument()}} >
                    {this.state.hasFullScrn === false && 
                    <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
                    }
                    </button>&nbsp;
                    <button onClick={() =>{this.tapToFullScreen()}} >
                    {this.state.hasFullScrn === false && 
                    <i className="fa fa-arrows-alt fa-2" aria-hidden="true"></i>
                    }
                      
                    {this.state.hasFullScrn === true && 
                    <i className="fa fa-window-minimize fa-2" aria-hidden="true"></i>
                    }
                    
                    </button>
                </div>
            </div>

                   
      </div>


    );

  }

}


export default StockMovementItem;